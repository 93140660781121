import InfoPages from './InfoPages/InfoPages'
import InfoPagesView from './InfoPages/InfoPagesView'
import InfoPagesCreate from './InfoPages/InfoPagesCreate'
import InfoPagesUpdate from './InfoPages/InfoPagesUpdate'

import CarouselItems from './CarouselItems/CarouselItems'
import CarouselItemsView from './CarouselItems/CarouselItemsView'
import CarouselItemsCreate from './CarouselItems/CarouselItemsCreate'
import CarouselItemsUpdate from './CarouselItems/CarouselItemsUpdate'

// import ErrorCodes from './ErrorCodes/ErrorCodes'
// import ErrorCodesView from './ErrorCodes/ErrorCodesView'
// import ErrorCodesCreate from './ErrorCodes/ErrorCodesCreate'
// import ErrorCodesUpdate from './ErrorCodes/ErrorCodesUpdate'

import Locations from './Locations/Locations'
import LocationsView from './Locations/LocationsView'
import LocationsCreate from './Locations/LocationsCreate'
import LocationsUpdate from './Locations/LocationsUpdate'

import PrepaidPlans from './PrepaidPlans/PrepaidPlans'
import PrepaidPlansView from './PrepaidPlans/PrepaidPlansView'
import PrepaidPlansCreate from './PrepaidPlans/PrepaidPlansCreate'
import PrepaidPlansUpdate from './PrepaidPlans/PrepaidPlansUpdate'

import PostpaidPlans from './PostpaidPlans/PostpaidPlans'
import PostpaidPlansView from './PostpaidPlans/PostpaidPlansView'
import PostpaidPlansCreate from './PostpaidPlans/PostpaidPlansCreate'
import PostpaidPlansUpdate from './PostpaidPlans/PostpaidPlansUpdate'

import FAQ from './FAQ/FAQ'
import FAQView from './FAQ/FAQView'
import FAQCreate from './FAQ/FAQCreate'
import FAQUpdate from './FAQ/FAQUpdate'

import Promotions from './Promotions/Promotions'
import PromotionsView from './Promotions/PromotionsView'
import PromotionsCreate from './Promotions/PromotionsCreate'
import PromotionsUpdate from './Promotions/PromotionsUpdate'

import BorrowCredit from './BorrowCredit/BorrowCredit'
import BorrowCreditView from './BorrowCredit/BorrowCreditView'
import BorrowCreditCreate from './BorrowCredit/BorrowCreditCreate'
import BorrowCreditUpdate from './BorrowCredit/BorrowCreditUpdate'

import AssetItems from './AssetItems/AssetItems'
import AssetItemsView from './AssetItems/AssetItemsView'
import AssetItemsCreate from './AssetItems/AssetItemsCreate'
import AssetItemsUpdate from './AssetItems/AssetItemsUpdate'

import AppVersions from './AppVersions/AppVersions'
import AppVersionsView from './AppVersions/AppVersionsView'
import AppVersionsCreate from './AppVersions/AppVersionsCreate'
import AppVersionsUpdate from './AppVersions/AppVersionsUpdate'

import Users from "./Users/Users";
import UsersView from "./Users/UsersView";
import UsersUpdate from "./Users/UsersUpdate";
import UsersOtherAccountUpdate from "./Users/UsersOtherAccountUpdate";

import Transactions from "./Transactions/Transactions";

const Routes = [
  {
    label: "Info Pages",
    route: "/info-pages",
    roles: ["admin", "marketing_team"],
    component: InfoPages,
    nodes: [
      { path: "/view/:id", component: InfoPagesView, label: "View Info Pages" },
      {
        path: "/edit/:id",
        component: InfoPagesUpdate,
        label: "Edit Info Pages",
      },
      {
        path: "/create/",
        component: InfoPagesCreate,
        label: "Create Info Pages",
      },
    ],
  },
  {
    label: "Carousel Items",
    route: "/carousel-items",
    roles: ["admin", "marketing_team"],
    component: CarouselItems,
    nodes: [
      {
        path: "/view/:id",
        component: CarouselItemsView,
        label: "View Carousel Items",
      },
      {
        path: "/edit/:id",
        component: CarouselItemsUpdate,
        label: "Edit Carousel Items",
      },
      {
        path: "/create/",
        component: CarouselItemsCreate,
        label: "Create Carousel Items",
      },
    ],
  },
  {
    label: "Prepaid Plans",
    route: "/prepaid-plans",
    roles: ["admin", "marketing_team"],
    component: PrepaidPlans,
    nodes: [
      {
        path: "/view/:id",
        component: PrepaidPlansView,
        label: "View Prepaid Plan",
      },
      {
        path: "/edit/:id",
        component: PrepaidPlansUpdate,
        label: "Edit Prepaid Plan",
      },
      {
        path: "/create/",
        component: PrepaidPlansCreate,
        label: "Create Prepaid Plan",
      },
    ],
  },
  {
    label: "Postpaid Plans",
    route: "/postpaid-plans",
    roles: ["admin"],
    component: PostpaidPlans,
    nodes: [
      {
        path: "/view/:id",
        component: PostpaidPlansView,
        label: "View Postpaid Plan",
      },
      {
        path: "/edit/:id",
        component: PostpaidPlansUpdate,
        label: "Edit Postpaid Plan",
      },
      {
        path: "/create/",
        component: PostpaidPlansCreate,
        label: "Create Postpaid Plan",
      },
    ],
  },
  {
    label: "FAQ",
    route: "/faq",
    roles: ["admin", "marketing_team"],
    component: FAQ,
    nodes: [
      { path: "/view/:id", component: FAQView, label: "View FAQ" },
      { path: "/edit/:id", component: FAQUpdate, label: "Edit FAQ" },
      { path: "/create/", component: FAQCreate, label: "Create FAQ" },
    ],
  },
  //======= Error codes lines are commented due to unuse. Remove when needed
  // {
  //     label: 'Error Codes',
  //     route: '/error-codes',
  //     roles: ['admin', 'technical_team'],
  //     component: ErrorCodes,
  //     nodes: [
  //         { path: '/view/:id', component: ErrorCodesView, label: 'View Error Code' },
  //         { path: '/edit/:id', component: ErrorCodesUpdate, label: 'Edit Error Code' },
  //         { path: '/create/', component: ErrorCodesCreate, label: 'Create Error Code' }
  //     ]
  // },
  {
    label: "Locations",
    route: "/locations",
    roles: ["admin"],
    component: Locations,
    nodes: [
      { path: "/view/:id", component: LocationsView, label: "View Location" },
      { path: "/edit/:id", component: LocationsUpdate, label: "Edit Location" },
      {
        path: "/create/",
        component: LocationsCreate,
        label: "Create Location",
      },
    ],
  },
  {
    label: "Promotions",
    route: "/promotions",
    roles: ["admin", "marketing_team"],
    component: Promotions,
    nodes: [
      {
        path: "/view/:id",
        component: PromotionsView,
        label: "View Promotions",
      },
      {
        path: "/edit/:id",
        component: PromotionsUpdate,
        label: "Edit Promotions",
      },
      {
        path: "/create/",
        component: PromotionsCreate,
        label: "Create Promotions",
      },
    ],
  },
  {
    label: "Borrow Credit",
    route: "/borrow-credit",
    roles: ["admin"],
    component: BorrowCredit,
    nodes: [
      {
        path: "/view/:id",
        component: BorrowCreditView,
        label: "View Borrow Credit",
      },
      {
        path: "/edit/:id",
        component: BorrowCreditUpdate,
        label: "Edit Borrow Credit",
      },
      {
        path: "/create/",
        component: BorrowCreditCreate,
        label: "Create Borrow Credit",
      },
    ],
  },
  // {
  //   label: "Asset Items",
  //   route: "/asset-items",
  //   roles: ["admin", "technical_team"],
  //   component: AssetItems,
  //   nodes: [
  //     {
  //       path: "/view/:id",
  //       component: AssetItemsView,
  //       label: "View Asset Item",
  //     },
  //     {
  //       path: "/edit/:id",
  //       component: AssetItemsUpdate,
  //       label: "Edit Asset Item",
  //     },
  //     {
  //       path: "/create/",
  //       component: AssetItemsCreate,
  //       label: "Create Asset Item",
  //     },
  //   ],
  // },
  {
    label: "App Versions",
    route: "/app-versions",
    roles: ["admin"],
    component: AppVersions,
    nodes: [
      {
        path: "/view/:id",
        component: AppVersionsView,
        label: "View App Version",
      },
      {
        path: "/edit/:id",
        component: AppVersionsUpdate,
        label: "Edit App Version",
      },
      {
        path: "/create/",
        component: AppVersionsCreate,
        label: "Create App Version",
      },
    ],
  },
  {
    label: "Users",
    route: "/users",
    roles: ["admin", "call_center_team"],
    component: Users,
    nodes: [
      { path: "/view/:id", component: UsersView, label: "View User" },
      { path: "/edit/:id", component: UsersUpdate, label: "Edit User" },
      {
        path: "/edit/:id/other-account/:account_id",
        component: UsersOtherAccountUpdate,
        label: "Edit Other Account",
      },
    ],
  },
  {
    label: "Transactions",
    route: "/transactions",
    roles: ["admin"],
    component: Transactions,
    nodes: [],
  },
];

export default Routes